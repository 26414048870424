<template>
  <div v-if="!isSubmissionComplete">
    <div
      v-if="dragAreaLoading"
      class="flex flex-col w-full lg:w-3/5 items-center justify-center m-auto"
    >
      <!-- Loader -->
      <div
        class="rounded-lg p-4 space-y-6 w-full customLoaderBorder h-64 m-auto"
      >
        <div class="flex gap-3">
          <div class="skeleton skeleton-button"></div>
          <div class="skeleton skeleton-button w-1/4"></div>
        </div>
        <div class="skeleton skeleton-text"></div>
        <div class="flex gap-2">
          <div class="skeleton skeleton-text w-3/4"></div>
          <div class="skeleton skeleton-text w-1/4"></div>
        </div>
        <div class="flex gap-2">
          <div class="skeleton skeleton-text w-1/2"></div>
          <div class="skeleton skeleton-text w-1/2"></div>
        </div>
      </div>
    </div>
    <CustomFormsQuestionType
      v-else
      :isDefault="consentFormInfo.isDefault"
      :questions="questions"
      @updateQuestion="questions = $event"
    />
  </div>
  <div class="flex items-center justify-center customCompleteForm" v-else>
    <div
      class="border rounded-lg border-grey border-solid flex flex-col gap-12 items-center justify-center lg:p-24 lg:px-32 md:p-16 p-12"
    >
      <vs-icon icon="check_box" class="text-primary" size="large"></vs-icon>
      <p class="lg:text-3xl text-xl text-center font-bold">
        Your form is complete.
      </p>
      <p class="lg:text-lg text-base text-center">
        Start using your consent form during your appointments <br />
        by selecting <b>{{ consentFormInfo.name }}</b> from your consent form list <br />
        during a treatment
      </p>
      <div class="flex flex-col items-center gap-3 w-full">
        <vs-button
          class="w-5/6 font-semibold lg:text-lg text-base mt-5"
          @click="handleRedirect"
          >Got it</vs-button
        >
        <p class="text-sm text-center">
          You will be automatically redirected to Consent Form in
          {{ timeLeft }} second(s)...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import draggable from "vuedraggable";
import vSelect from "vue-select";
import CustomFormsQuestionType from "./CustomFormsQuestionType.vue";

export default {
  components: {
    draggable,
    "v-select": vSelect,
    CustomFormsQuestionType,
  },
  data: () => ({
    consentFormInfo: {
      name: "",
      isDefault: false,
    },
    questions: [],
    dragAreaLoading: true,
    isSubmissionComplete: false,
    timeLeft: 1,
    timerInterval: null,
  }),
  methods: {
    ...mapActions("consentForms", [
      "fetchConsentFormInfo",
      "createCustomForm",
      "updateCustomForm",
    ]),
    areQuestionsValid() {
      return this.questions.every(question => 
        question.question.trim() !== '' && this.validateOptions(question) && question.type !== null
      );
    },
    handleRedirect() {
      const role = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );
      const path = role === "Org Owner" ? "org-owner" : "nurse";
      this.$router.push(`/${path}/consent-forms`);
    },
    automaticRedirectTimer() {
      this.timeLeft = 10;
      this.timerInterval = setInterval(() => {
        if (this.timeLeft > 1) {
          this.timeLeft--;
        } else {
          clearInterval(this.timerInterval);
          this.handleRedirect();
        }
      }, 1000);
    },
    resetTimer() {
      this.timeLeft = 1; // Reset the timer data
      if (this.timerInterval) {
        clearInterval(this.timerInterval); // Clear the interval if it exists
      }
    },
    async fetchConsentInfoAndQuestions() {
      try {
        const { params } = this.$route;
        const org_id = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
        );
        const result = await this.fetchConsentFormInfo({
          id: params.consentId,
          params: {
            orgId: org_id,
          },
        });
        const info = result.data.data;

        if (info) {
          const temp = { ...info };
          delete temp.questions
          this.$store.dispatch("consentForms/updateConsentFormDetails", temp);
        }

        if (info.questions.length) {
          this.questions = info.questions.map((item) => {
            let type = item.type;
            if (!item.type) {
              type = "yesOrNo"
            }
            return {
              ...item,
              type
            }
          })
        }

        this.$nextTick(function () {
          this.dragAreaLoading = false;
        });
      } catch (error) {
        this.dragAreaLoading = false;
        console.log(error);
      }
    },
    saveData() {
      if (!this.areQuestionsValid()) {
        this.$store.dispatch("consentForms/updatedFormSubmission", false);
        this.$vs.notify({
          title: "Validation Error",
          text: "All questions must have content and all options for multi-select or single-select questions must have values. Please fill in all required fields.",
          color: "danger",
        });
        return;
      }

      if (this.isAddMode) return this.handleCreateCustomForm();
      this.handleUpdateCustomForm();
    },
    async handleCreateCustomForm() {
      try {
        let isValid = await this.$validator.validateAll("basic");
        await this.$validator.validate();
        if (isValid && this.areQuestionsValid()) {
          const orgId = localStorage.getItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
          );

          const formattedQuestions = this.questions.map((item) => {
            let type = item.type;
            if (item.type === "yesOrNo") {
              type = null;
            }
            return {
              ...item,
              type
            }
          })
          const payload = {
            name: this.consentFormInfo.name,
            ...(orgId && { orgId }),
            questions: formattedQuestions,
          };
          const res = await this.createCustomForm(payload);
          if (res.status == 200) {
            this.$store.dispatch("consentForms/updatedFormSubmission", false);
            this.$store.dispatch(
              "consentForms/updateConsentFormCompletion",
              true
            );
            this.automaticRedirectTimer();
          }
        } else {
          this.$vs.notify({
            title: "Validation Error",
            text: "Please ensure all fields are filled correctly.",
            color: "danger",
          });
        }
      } catch (err) {
        this.$store.dispatch("consentForms/updatedFormSubmission", false);
        console.log("###Custom Form Err: ", err);
        this.$vs.notify({
          title: "Consent Form Error",
          text: err.data.message,
          color: "danger",
        });
      }
    },
    async handleUpdateCustomForm() {
      try {
        let isValid = await this.$validator.validateAll("basic");
        await this.$validator.validate();
        if (isValid && this.areQuestionsValid()) {
          const orgId = localStorage.getItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
          );
          const formattedQuestions = this.questions.map((item, index) => {
            let type = item.type;
            if (item.type === "yesOrNo") {              
              type = null;
            }
            const data = {
              question: item.question,
              is_mandatory: item.is_mandatory,
              order: index + 1,
              type
            };
            if (item._id) {
              data["_id"] = item._id;
            }
            if (["multipleChoice", "singleSelect"].includes(item.type)) {
              data["options"] = item.options;
            } else {
              data["options"] = [];
            }
            return data;
          });

          const payload = {
            consentId: this.consentFormInfo._id,
            body: {
              name: this.consentFormInfo.name,
              ...(orgId && { orgId }),
              questions: formattedQuestions,
            },
          };
          const res = await this.updateCustomForm(payload);
          if (res.status == 200) {
            this.$store.dispatch("consentForms/updatedFormSubmission", false);
            this.$store.dispatch(
              "consentForms/updateConsentFormCompletion",
              true
            );
            this.automaticRedirectTimer();
          }
        } else {
          this.$vs.notify({
            title: "Validation Error",
            text: "Please ensure all fields are filled correctly.",
            color: "danger",
          });
        }
      } catch (err) {
        this.$store.dispatch("consentForms/updatedFormSubmission", false);
        console.log("###Custom Form Err: ", err);
        this.$vs.notify({
          title: "Consent Form Error",
          text: err.data.message,
          color: "danger",
        });
      }
    },
    isDefault(question) {
      if (typeof question.isDefault === "boolean") return question.isDefault;
      return true;
    },
    validateOptions(question) {
      if (['multipleChoice', 'singleSelect'].includes(question.type)) {
        return question.options && question.options.every(option => 
          option.value !== null && 
          option.value !== undefined && 
          String(option.value).trim() !== ''
        );
      }
      return true;
    },
  },
  computed: {
    headerLabel() {
      if (this.$route.name.search("Create") !== -1) return "Create";
      return "Update";
    },
    btnLabel() {
      if (this.$route.name.search("Create") !== -1) return "Save";
      return "Update";
    },
    isAddMode() {
      return this.btnLabel === "Save";
    },
    isSubmitting() {
      return this.$store.getters["consentForms/isFormSubmitting"];
    },
    consentFormDetails() {
      return this.$store.getters["consentForms/getConsentForm"];
    },
    submissionCompleted() {
      return this.$store.getters["consentForms/isSubmissionCompleted"];
    },
  },
  async created() {
    if (this.$route.name.search("Details") !== -1) {
      this.fetchConsentInfoAndQuestions();
    } else {
      this.dragAreaLoading = false;
    }
  },
  watch: {
    isSubmitting: {
      handler(disableBtn) {
        if (disableBtn) {
          this.saveData();
        }
      },
    },
    consentFormDetails: {
      handler(newConsentFormInfo) {
        this.consentFormInfo = { ...newConsentFormInfo };
      },
      immediate: true,
    },
    submissionCompleted: {
      handler(isCompleted) {
        this.isSubmissionComplete = isCompleted;
      },
    },
  },
  beforeDestroy() {
    this.resetTimer();
    this.$store.dispatch("consentForms/updateConsentFormDetails", {});
    this.$store.dispatch("consentForms/updatedFormSubmission", false);
    this.$store.dispatch("consentForms/updateConsentFormCompletion", false);
  },
};
</script>

<style>
.customCompleteForm {
  height: 70dvh;
}
.skeleton-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 200px;
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

.skeleton-button {
  width: 80%;
  height: 50px;
}

.skeleton-text {
  height: 24px;
}

/* Animation for pulse effect */
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.customLoaderBorder {
  border: 1px solid #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}
</style>